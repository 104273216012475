import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { SEO, GeneralJumbotron, SummaryTemplate, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { HospitalsImagesQuery } from '../shared/interfaces';

const HospitalsPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<HospitalsImagesQuery>(graphql`
        query {
            hospitalsBackgroundImage: file(name: {eq: "hospitalsBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
            hospitalsSummaryImage: file(name: {eq: "hospitalsSummary"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Hospitals"/>
            <GeneralJumbotron
                title="Hospitals"
                backgroundImageURL={queryResult?.hospitalsBackgroundImage.publicURL}
                backgroundText="Hospitals"
            />
            <SummaryTemplate image={queryResult?.hospitalsSummaryImage.publicURL}>
                <p>
                    {`
                        We understand that some cases are complex or catastrophic and
                        require hospital care. Without private insurance, most clients
                        have had nowhere to turn. Until now!
                    `}
                </p>
                <p>
                    {`
                        PCH IPA is proud to have an extensive, statewide network that
                        includes hospitals! We even offer post-rehab solutions!
                    `}
                </p>
                <strong>{`COMING SOON—Emergency Services`}</strong>
                <p>
                    {`
                        A highly qualified emergency department team provides outstanding
                        evaluation and treatment for patients through a full spectrum of
                        emergency medical services.
                    `}
                </p>
                <p>
                    {`To learn more, call us at `}
                    <a href="tel:+18337244111">
                        <FontAwesomeIcon icon={faPhoneAlt}/>
                        {`1-833-PCH-4111`}
                    </a>
                </p>
            </SummaryTemplate>
            {/* <CallToAction/> */}
        </MainLayout>
    );
}

export default HospitalsPage;
